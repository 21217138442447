<template>
  <div class="glo_btnList_right">
    <el-button
      class="glo_bgcMainColor"
      @click="queryBtn_ok"
      icon="el-icon-search"
      >查询</el-button
    >
    <el-tooltip v-if="showcancelBtns" content="重置查询条件" placement="top">
      <div class="textBtn" @click="clerBtns">重置</div>
    </el-tooltip>
    <el-tooltip v-if="showMoreBtns" content="展开收起更多条件" placement="top">
      <div class="textBtn" @click="moreParam">{{ MoreConditions }}</div>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    MoreConditions: {
      typeof: String,
      default: "",
    },
    showMoreBtns: {
      typeof: Boolean,
      default: true,
    },
    showcancelBtns: {
      typeof: Boolean,
      default: true,
    },
  },
  methods: {
    moreParam() {
      this.$emit("moreParam");
    },
    queryBtn_ok() {
      this.$emit("queryBtn_ok");
    },
    clerBtns() {
      this.$emit("clerBtns");
    },
  },
};
</script>

<style lang='scss'>
// 主题色
$mainColor: #ef7c0a;
.diaBtns {
  width: 80px;
  padding: 10px;
}

.glo_btnList_right {
  display: flex;
  align-items: center;
  margin-left: 20px;
  max-width: 180px;
  margin-bottom: 10px;

  .textBtn {
    font-size: 13px;
    margin-left: 12px;
    color: #2c6ae1;
    cursor: pointer;
  }
}

//按钮 主题色底,白字
.glo_bgcMainColor {
  background-color: $mainColor !important;
  color: #fff !important;
  border: none !important;
}
</style>