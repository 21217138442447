<template>
  <!-- 数据字典 -->
  <div>
    <div class="box">
      <!-- 左边 -->
      <div class="boxleft glo_Table">
        <el-table
          :data="menuTableData"
          style="width: 100%"
          row-key="departmentId"
          border
          :height="leftHeight"
          default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          @row-click="rowClickLeft"
        >
          <el-table-column
            prop="typeShow"
            label="字典类型名称"
            sortable
            min-width="170"
          >
          </el-table-column>
        </el-table>
      </div>

      <!-- 右边 -->
      <div class="boxright">
        <!-- 按钮列表 -->
        <div class="glo_btnList" ref="btnList">
          <div class="glo_btnList_left">
            <el-button class="glo_btnItems glo_bgcMainColor" @click="menuadd()"
              >新增字典
            </el-button>
            <el-button class="glo_btnItems" @click="delBtns('more')"
              >批量删除
            </el-button>
            <el-button class="glo_btnItems" @click="qiYong('more')"
              >批量启用
            </el-button>
            <el-button class="glo_btnItems" @click="jinYong('more')"
              >批量禁用
            </el-button>
          </div>

          <queryBtns
            :MoreConditions="MoreConditions"
            :showMoreBtns="false"
            @clerBtns="clerBtns"
            @queryBtn_ok="queryBtn_ok"
            @moreParam="moreParam"
          ></queryBtns>
        </div>
        <!-- 查询条件 -->
        <div ref="queryForm">
          <el-form
            :model="queryData"
            :class="isHaseMore ? 'glo_form hasMoreParam' : 'glo_form'"
            label-position="left"
            label-width="100px"
          >
            <el-form-item class="queryItems" label="字典类型:">
              <el-select
                v-model="queryData.type"
                filterable
                clearable
                default-first-option
                placeholder="请选择"
                @change="queryBtn_ok"
              >
                <el-option
                  v-for="(item2, index2c) in menuTableData"
                  :key="index2c"
                  :label="item2.typeShow"
                  :value="item2.type"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item class="queryItems" label="显示值名称:">
              <el-input
                clearable
                v-model="queryData.val"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>

        <!-- 表格 -->
        <div class="glo_Table" ref="tableWrapper">
          <el-table
            :height="tableHeigth"
            ref="mytable"
            @row-click="rowclick"
            :data="tableData"
            stripe
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column fixed="left" label="序号" type="index" width="60">
            </el-table-column>
            <el-table-column fixed="left" type="selection" width="60">
            </el-table-column>
            <template v-for="(item, index) in myTableHeard">
              <el-table-column
                v-if="item.field == 'isShowShow'"
                :key="index"
                :label="item.name"
                sortable
                :min-width="item.width"
              >
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.isShowShow == '禁用'"
                    style="color: #f00"
                  >
                    {{ scope.row.isShowShow }}
                  </div>
                  <div v-else>{{ scope.row.isShowShow }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-else-if="item && item.isShow"
                :key="index"
                :label="item.name"
                :prop="item.field"
                sortable
                :min-width="item.width"
                :data-isTotal="item.isTotal"
              >
              </el-table-column>
            </template>
            <el-table-column fixed="right" label="操作" min-width="220px">
              <template slot-scope="scope">
                <div class="tabRightBtns">
                  <el-button
                    class="rbtns"
                    @click.stop="menumodify(scope.row, 'right')"
                    >编辑</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="qiYong('one', scope.row.confDictId)"
                    >启用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="jinYong('one', scope.row.confDictId)"
                    >禁用</el-button
                  >
                  <el-button
                    class="rbtns"
                    @click.stop="delBtns('one', scope.row.confDictId)"
                    >删除</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页组件 -->
        <paging
          ref="pags"
          :pageNum="pageStart"
          :total="total"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        ></paging>
      </div>
    </div>

    <!-- 弹窗 -->
    <myDialog
      :diaWidth="diaWidth"
      :dialogTitle="PopUpTitle"
      :isShowDialog="menushowBOX"
      @handleCloseDia="handleCloseDia"
      @diaCancelBtn="diaCancelBtn"
      @diaConfirmBtn="diaConfirmBtn('dialogFormDataRef')"
    >
      <div class="glo_dialogForm">
        <el-form
          class="diaForm"
          v-if="menushowBOX"
          ref="dialogFormDataRef"
          :model="dialogFormData"
          label-width="100px"
          :rules="rules"
        >
          <el-form-item class="dialogFormItems" prop="bbb" label="字典类型:">
            <el-select
              v-model="dialogFormData.type"
              clearable
              filterable
              placeholder="请选择"
              :disabled="dialogType == 'edit'"
            >
              <el-option
                v-for="(item, index) in menuTableData"
                :key="index"
                :label="item.typeShow"
                :value="item.type"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="val" label="显示值:">
            <el-input
              placeholder="请输入"
              clearable
              v-model="dialogFormData.val"
            ></el-input>
          </el-form-item>
          <el-form-item class="dialogFormItems" prop="isShow" label="状态:">
            <el-select
              clearable
              filterable
              v-model="dialogFormData.isShow"
              placeholder="请选择"
            >
              <el-option
                v-for="item in isShowList"
                :key="item.val"
                :label="item.name"
                :value="item.val"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="dialogFormItems" prop="sort" label="排序:">
            <el-input
              placeholder="请输入"
              v-model="dialogFormData.sort"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            class="dislogFormTextarea"
            prop="descript"
            label="备注:"
          >
            <el-input
              type="textarea"
              rows="2"
              v-model="dialogFormData.descript"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </myDialog>
  </div>
</template>


<script>
import queryBtns from "@/components/queryBtns.vue";
import myDialog from "@/components/Dialog.vue";

import paging from "@/components/pagings.vue";
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
export default {
  inject: ["reload"],
  components: {
    paging,
    queryBtns,
    myDialog,
  },
  data() {
    return {
      bumenTypeList: [], //部门类型
      dialogFormData: {
        descript: "", //描述
        isShow: "1", //是否启用 0=否 1=是
        type: "", //字典类型
        typeShow: "", //字典类型展示值
        sort: "1", //
        val: "", //字典值
      },
      rules: {
        departmentName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        parentId: [{ required: true, message: "请输入", trigger: "blur" }],
        aaa: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      PopUpTitle: "", //弹窗标题
      menushowBOX: false, //菜单弹窗
      dialogType: "add",
      menuTableData: [],
      diaWidth: "40%",
      //\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

      queryData: {
        val: "",
        type: "",
      },

      // ================
      pageStart: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      pageTotal: 100, //一页几条
      nextPage: false, //是否有下一页
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      leftHeight: "75vh",
      tableHeigth: "75vh",
      tabelHeadeTitle: [
        {
          name: "字典类型",
          field: "typeShow",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "显示值",
          field: "val",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "状态",
          field: "isShowShow",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "100",
        },
        {
          name: "是否出厂配置",
          field: "isInitializationShow",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "130",
        },
        {
          name: "备注",
          field: "descript",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "160",
        },
        {
          name: "排序",
          field: "sort",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "80",
        },
        {
          name: "新建时间",
          field: "createTime",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "160",
        },
        {
          name: "申请人",
          field: "createUserName",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "修改时间",
          field: "updateTime",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
        {
          name: "修改人",
          field: "updateUserName",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: "120",
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      sortName: "组织架构", //排序名
      pageGroupName: "organizationalStructure", //页面标识
      selectTableData: [], //选中的数据

      deperIds: [],
      MoreConditions: "更多条件", //  输入框绑定动态class
      isHaseMore: false, //false == 更多条件  true ==收起条件
      isShowList: [
        {
          val: "1",
          name: "启用",
        },
        {
          val: "0",
          name: "禁用",
        },
      ],
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.myTableHeard = this.tabelHeadeTitle;
    this.departmentHierarchy(); //左边列表
  },
  mounted() {
    const that = this;
    this.getData();
    // this.getStatusValList("finance_in_record_v1.approve_status") || []; //审核状态列表
    // this.getdepartmentTypeList();
  },
  activated() {
    this.$nextTick(() => {
      this.fetTableHeight();
    });
  },
  watch: {},
  methods: {
    rowClickLeft(e) {
      console.log(e);
      this.queryData.type = e.type;
      this.getData();
    },
    // 点击选中当前行
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    clerBtns() {
      //  重置按钮
      this.queryData = {
        val: "",
        type: "",
      };
    },

    moreParam() {
      this.isHaseMore = !this.isHaseMore;
      if (this.isHaseMore) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }

      this.$nextTick(() => {
        this.fetTableHeight();
      });
    },

    // 递归获取部门下所有id
    getDpIds(data) {
      let ids = this.deperIds || [];
      ids.push(data.departmentId);
      let arrs = data.children || [];
      for (var i = 0; i < arrs.length; i++) {
        ids.push(arrs[i].departmentId);
        let arr2 = arrs[i].children || [];
        for (var j = 0; j < arr2.length; j++) {
          this.getDpIds(arr2[j]);
        }
      }
      this.deperIds = ids;
      return this.deperIds;
    },
    // 部门类型下拉列表
    getdepartmentTypeList() {
      Api.getdepartmentTypeList().then((res) => {
        if (res.data.status == "success") {
          let arrs = res.data.result || [];
          this.bumenTypeList = arrs;
        }
      });
    },
    // 部门层级列表
    departmentHierarchy() {
      Api.wtl_getConfDictTypeList().then((res) => {
        if (res.data.status == "success") {
          let bmList = res.data.result || [];
          this.menuTableData = bmList;
        }
      });
    },

    //菜单新建
    menuadd() {
      this.dialogFormData.type = this.queryData.type;
      // this.dialogFormData.typeShow = row.typeShow;
      this.PopUpTitle = "新建字典";
      this.menushowBOX = true;
      this.dialogType = "add";

      console.log(this.dialogFormData);
    },
    //菜单修改
    menumodify(row, types) {
      console.log(row);

      this.dialogFormData.type = row.type || "";
      this.dialogFormData.typeShow = row.typeShow || "";
      this.dialogFormData.sort = row.sort || "1";
      this.dialogFormData.descript = row.descript || "";
      this.dialogFormData.isShow = row.isShow + "" || "1";
      this.dialogFormData.val = row.val || "";
      this.dialogFormData.confDictId = row.confDictId || "";

      this.PopUpTitle = "字典编辑";
      this.menushowBOX = true;
      this.dialogType = "edit";

      // if (types == "left") {
      // } else {
      // }
    },
    // 删除
    delBtns(types, id) {
      let ids = [];
      if (types == "more") {
        //批量删除
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要删除的字典数据");
          return false;
        } else {
          this.selectTableData.forEach((item) => {
            ids.push(item.confDictId);
          });
        }
      } else {
        // 单个删除
        ids = [id];
      }
      this.$confirm("是否删除所选字典?", "提示:")
        .then(() => {
          let param = {
            confDictIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.wtl_delConfDict(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    //启用
    qiYong(types, id) {
      let ids = [];
      if (types == "more") {
        //批量
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要启用的字典数据");
          return false;
        } else {
          this.selectTableData.forEach((item) => {
            ids.push(item.confDictId);
          });
        }
      } else {
        // 单个删除
        ids = [id];
      }
      this.$confirm("是否启用所选字典?", "提示:")
        .then(() => {
          let param = {
            confDictIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.wtl_startConfDict(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },

    //禁用
    jinYong(types, id) {
      let ids = [];
      if (types == "more") {
        //批量
        if (this.selectTableData.length < 1) {
          this.$message.warning("请选择需要禁用的字典数据");
          return false;
        } else {
          this.selectTableData.forEach((item) => {
            ids.push(item.confDictId);
          });
        }
      } else {
        // 单个删除
        ids = [id];
      }
      this.$confirm("是否禁用所选字典?", "提示:")
        .then(() => {
          let param = {
            confDictIds: ids,
          };
          param.sign = tools.getSign(param);
          Api.wtl_stopConfDict(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "操作成功");
              this.getData();
            }
          });
        })
        .catch(() => {});
    },
    /***
     * ====== 弹窗组件方法 ======
     */
    //  弹窗关闭事件(左上角的x和关闭按钮都算)
    handleCloseDia() {
      this.menushowBOX = false;
      this.dialogFormData = {
        descript: "", //描述
        isShow: "1", //是否启用 0=否 1=是
        type: "", //字典类型
        typeShow: "", //字典类型展示值
        sort: "1", //
        val: "", //字典值
      };
    },
    //  弹窗确认保存事件
    diaConfirmBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.menuTableData.forEach((item) => {
            if (item.type == this.dialogFormData.type) {
              this.dialogFormData.typeShow = item.typeShow;
            }
          });
          if (this.dialogType == "add") {
            // 新建
            this.dialogFormData.sign = tools.getSign(this.dialogFormData);
            Api.wtl_addConfDict(this.dialogFormData).then((res) => {
              this.$message.success(res.data.message || "新建成功");
              this.menushowBOX = false;
              tools.resetForm(this, formName);
              this.getData();
            });
          } else {
            // 修改
            this.dialogFormData.sign = tools.getSign(this.dialogFormData);
            Api.wtl_udpateConfDict(this.dialogFormData).then((res) => {
              this.$message.success(res.data.message || "修改成功");
              this.menushowBOX = false;
              tools.resetForm(this, formName);
              this.getData();
            });
          }
        }
      });
    },
    //  关闭弹窗按钮事件(关闭按钮)
    diaCancelBtn() {
      this.menushowBOX = false;
    },

    /***
     * ====== 弹窗组件方法 ======
     */

    // 表格选择事件
    handleSelectionChange(e) {
      this.selectTableData = e;
    },

    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */

    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    /***
     * ==============设置表格动态高度==============
     */
    fetTableHeight() {
      // 重置table高度
      this.tabelHeigth = 0;
      // 设置table高度
      let windonHeight =
        document.body.clientHeight || document.documentElement.clientHeight; //页面高度
      let queryHeigth = 40 + 10; //10==margin-top: 10  查询框高度
      let btnListHeight = this.$refs.btnList.offsetHeight; // 按钮列表高度
      if (this.isHaseMore) {
        queryHeigth = this.$refs.queryForm.offsetHeight + 10; //查询框
      }
      let pagsHeigth = 40 + 20; //分页组件高度 20是上下margin
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60; //60是顶部导航 10是
      let tabHeight = Math.floor(windonHeight - otherHeight) || ""; //

      this.$nextTick(() => {
        this.tableHeigth = tabHeight;

        this.leftHeight = windonHeight - 85;
        this.$refs.mytable.doLayout();
      });
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 获取数据
    getData() {
      let param = {
        val: this.queryData.val,
        type: this.queryData.type,
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
      };
      Api.wtl_getConfDictList(param).then((res) => {
        // this.className = res.data.result.className || "";
        this.tableData = res.data.result.data || [];
        this.total = res.data.result.pageCount || 0;
        this.fetTableHeight();
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageStart = 1;
      this.getData();
    },

    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.pageTotal = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageStart = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang='scss'>
// 主题色
$mainColor: #ef7c0a;
// 按钮边框
$btnBorderColor: #cccccc;
// $btnBorderColor: #606266;
// 查询条件边框线
$borderColor: #cccccc;
// 表格右边按钮背景色
$TbRightColor: #0390cf;

//按钮 主题色底,白字
.glo_bgcMainColor {
  background-color: $mainColor !important;
  color: #fff !important;
  border: none !important;
}

.box {
  // width: 100%;
  display: flex;
  .boxleft {
    width: 200px;
    margin-right: 30px;
  }
  .boxright {
    flex: 1;
    overflow: auto;
  }
}

.tablebut {
  margin-left: 15px;
}
.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryCriteria {
  .queryItem_one {
    .queryTextarea {
      border: 1px solid #d7d7d7;
    }
  }
  .timeBox {
    border: 1px solid #d7d7d7;
  }
}

.headerbox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerbox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.newForm {
  margin-left: 80px;
  .queryItem1 {
    width: 60%;
    margin-top: 20px;
    display: flex;
    .inputstyle {
      width: 100%;
      display: flex;
    }
  }
}

.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input,
.HeadInputbox .item_right .singleinp1 .el-date-editor.el-input__inner {
  width: 100%;
}

.frominput ::v-deep {
  .el-input__inner {
    height: 30px;
  }
}

// ============= 表格 start ================

.glo_Table {
  // 右边固定按钮
  .tabRightBtns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      padding: 5px 6px;
      font-size: 13px;
    }
    .el-dropdown {
      font-size: 13px;
      border-radius: 4px;
    }

    // 右边固定按钮样式
    .rbtns {
      // min-width: 50px;
      color: #fff;
      background-color: $TbRightColor;
      margin-left: 5px;
      margin-right: 5px;
    }

    .el-button:hover {
      background-color: $mainColor;
      border: 1px solid $mainColor;
    }
  }
  // 表头颜色
  .el-table th.el-table__cell {
    background-color: #f0f0f0;
  }
  .el-table .el-table__cell {
    padding: 0;
  }
  .el-table th.el-table__cell > .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #fff;
  }

  .el-table tr {
    height: 34px;
  }
  .el-table .el-table__cell {
    text-overflow: ellipsis;
  }

  //鼠标停留的颜色
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #ffeab6 !important;
  }
  //当前选中行颜色
  .el-table__body tr.current-row > td {
    background-color: #ffeab6 !important;
  }

  .el-table__row--striped {
    background-color: #f00;
  }
  // 可以点击跳转的列
  .linkTabel {
    color: #02a7f0;
    cursor: pointer;
  }
}
// ============= 表格 end ================

// ============= 弹出层  start ================
.el-dialog {
  margin: 80px auto 40px !important;
  min-width: 600px;
}

.el-dialog--center {
  max-height: calc(100vh - 120px);
  min-height: 100px;
  padding: 0;
  box-sizing: border-box;
}
.el-dialog__header {
  border-bottom: 1px solid $borderColor;
  margin: 0;
  padding: 0;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.el-dialog__body {
  padding: 10px 15px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  box-sizing: border-box;
}

.el-dialog__footer {
  border-top: 1px solid #d7d7d7;
  height: 54px;
  padding: 5px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

// 弹窗上下居中
.el-dialog__wrapper {
  display: flex;
  align-items: center;
}
// ============= 弹出层 end ================

// =============== 列表顶部按钮样式(全局)=========
.glo_btnList {
  display: flex;
  justify-content: space-between;
  // padding-bottom: 10px;

  .el-button {
    min-width: 64px;
    padding: 5px;
  }

  .glo_btnList_left {
    flex: 1;
    .glo_btnItems {
      border: 1px solid $btnBorderColor;
      background-color: #fff;
      color: #333;
      padding: 5px;
      margin-left: 10px;
      margin-bottom: 8px;
    }

    .el-button:hover {
      border: 1px solid $mainColor;
      // border: 1px solid #cccccc;
      color: $mainColor;
    }
  }

  .glo_btnList_right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    max-width: 180px;
    margin-bottom: 10px;

    .textBtn {
      font-size: 13px;
      margin-left: 12px;
      color: #2c6ae1;
      cursor: pointer;
    }
  }

  .topDropDown {
    margin-left: 10px;
  }
  .el-button--primary {
    background-color: #fff;
    color: #333;
    border: 1px solid $btnBorderColor;
  }
}
// 页面查询条件样式

.glo_form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  height: 40px;
  overflow: hidden;

  //element UI
  .el-form-item__label {
    line-height: 30px;
    height: 30px;
    text-align: left;
    width: 90px !important;
  }
  .el-form-item {
    margin-bottom: 10px;
  }

  /deep/ .el-input__inner {
    height: 30px;
    line-height: 30px;
    border: none;
  }
  /deep/ .el-form-item__content {
    height: 30px;
    line-height: 30px;
    margin: 0 !important;
  }
  /deep/ .el-form-item__label {
    line-height: 30px;
    height: 30px;
  }

  // 除了时间外的其他查询条件样式
  .queryItems {
    width: 230px;
    margin-right: 10px;
    display: flex;
    border: 1px solid $borderColor;
    padding: 0 5px;
    height: 33px;
    line-height: 33px;
    box-sizing: border-box;
    border-radius: 4px;

    .el-form-item__content {
      height: 30px;
      line-height: 30px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: 0;
      width: 120px;
      padding: 0 4px;
    }
    // 下拉选择框右边图标
    .el-input__icon {
      line-height: 30px;
    }
  }
  .queryItems:hover {
    border: 1px solid $mainColor;
    box-shadow: 0px 0px 5px $mainColor;
  }
  // 时间查询
  .queryItemsTimes {
    width: 470px;
    margin-right: 10px;
  }
  // 全字段查询
  .queryItemsAllQuery {
    width: 470px;
    margin-right: 10px;

    .el-form-item__content {
      flex: 1;
    }

    .AllQueryItems {
      display: flex;

      .AllQuerySelect {
        flex: 1;
        border-right: 1px solid $borderColor;
      }
      .AllQueryInput {
        flex: 1;
      }
    }
  }
}
// 展开查询条件时样式
.hasMoreParam {
  height: auto;
}

// 除了时间外的其他查询条件样式
.queryItems {
  width: 230px;
  margin-right: 10px;
  display: flex;
  border: 1px solid $borderColor;
  padding: 0 5px;
  height: 33px;
  line-height: 33px;
  box-sizing: border-box;
  border-radius: 4px;

  .el-form-item__content {
    height: 30px;
    line-height: 30px;
    // overflow: hidden;
  }
  .el-input__inner {
    height: 30px;
    line-height: 30px;
    border: 0;
    width: 120px;
    padding: 0 4px;
  }
  // 下拉选择框右边图标
  .el-input__icon {
    line-height: 30px;
  }
}
</style>